import { Navigate, Route, Routes, BrowserRouter } from 'react-router-dom';
import './App.css';
import Navbar from './pages/component/Navbar';
import Passcheck from './routes';
import * as React from 'react';
import Login from './pages/auth/Login';
import InforUser from './pages/auth/InforUser';
import ListCustomer from './pages/customer/ListAllCustomer';
import CheckRoute from './routes/CheckRoute';
import InforCustomer from './pages/customer/InforDetailCustomer';
import AddCustomer from './pages/customer/AddCustomer';
import QuickAddCustomer from './pages/customer/QuickAddCustomer';
import ListUser from './pages/user/ListAllUser';
import AddUser from './pages/user/AddUser';
import InforDetailUser from './pages/user/InforDetailUser';
import ListPackage from './pages/packages/ListAllPackage';
import InforDetailPackage from './pages/packages/InforDetailPackage';
import AddPackage from './pages/packages/AddPackage';
import InforSetting from './pages/setting/InforSetting';
import ListAllOrder from './pages/order/ListAllOrder';
import ListPendingOrder from './pages/order/ListPendingOrder';
import InforDetailSetting from './pages/setting/InforDetailSetting';
import TimeSetting from './pages/setting/TimeSetting';
import DomainSetting from './pages/setting/DomainSetting';
import QuickAddDomain from './pages/customer/PublicAddDomain';
import ListFamilyDomain from './pages/family/ListDomainFamily';
function App() {
  return (
  
      <div className='Main' style={{width:"100%", overflow: "auto",}}>
        <BrowserRouter>
          <Routes>
            <Route path = "/" element = {<Passcheck />}/>
            <Route path = "/login" element = {<CheckRoute><Login /></CheckRoute>}/>
            <Route path = "customer" element = {<CheckRoute><Navbar/><ListCustomer/></CheckRoute>}/>
            <Route path = "/customer/:id/:familyid" element= {<CheckRoute><Navbar/><InforCustomer/></CheckRoute>}/>
            <Route path = "/customer/addnew" element= {<CheckRoute><Navbar/><AddCustomer/></CheckRoute>}/>
            <Route path = "/infor-user" element = {<CheckRoute><Navbar/><InforUser/></CheckRoute>}/>
            <Route path = "quick-add" element = {<QuickAddCustomer/>}/>
            <Route path = "domain-add/:id" element = {<QuickAddDomain/>}/>
            <Route path = "user" element = {<CheckRoute><Navbar/><ListUser/></CheckRoute>}/>
            <Route path = "user/:id" element = {<CheckRoute><Navbar/><InforDetailUser/></CheckRoute>}/>
            <Route path = "user/addnew" element = {<CheckRoute><Navbar/><AddUser/></CheckRoute>}/>
            <Route path = "package" element = {<CheckRoute><Navbar/><ListPackage/></CheckRoute>}/>
            <Route path = "package/:id" element = {<CheckRoute><Navbar/><InforDetailPackage/></CheckRoute>}/>
            <Route path = "package/addnew" element = {<CheckRoute><Navbar/><AddPackage/></CheckRoute>}/>
            <Route path = "/email-setting" element = {<CheckRoute><Navbar/><InforSetting/></CheckRoute>}/>
            <Route path = "/infor-setting" element = {<CheckRoute><Navbar/><InforDetailSetting/></CheckRoute>}/>
            <Route path = "/time-setting" element = {<CheckRoute><Navbar/><TimeSetting/></CheckRoute>}/>
            <Route path = "/order" element = {<CheckRoute><Navbar/><ListAllOrder/></CheckRoute>}/>
            <Route path = "/order/pending" element = {<CheckRoute><Navbar/><ListPendingOrder/></CheckRoute>}/>
            <Route path = "/domain-setting" element = {<CheckRoute><Navbar/><DomainSetting/></CheckRoute>}/>
            <Route path = "/domain-list" element = {<CheckRoute><Navbar/><ListFamilyDomain/></CheckRoute>}/>
            <Route path = "*" element = {<Navigate to={'/'}></Navigate>}/>

            {/* <Route path = "ten-mien/:id" element = {<QuickAddDomain/>}/>
            <Route path = "*" element = {<QuickAddCustomer/>}/> */}
          </Routes>
        </BrowserRouter>
      </div>
  );
}

export default App;
